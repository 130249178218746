import React from "react"
import section_break from "../../content/assets/section-break.svg"

class SectionBreak extends React.Component {
    render() {
        return (
            <div style={{
                display: `flex`,
                justifyContent: `center`,
                margin: `3.3rem 0rem`,
            }}>
                <img src={section_break} alt="Section break"/>
            </div>
        )
    }
}

export default SectionBreak
