import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleSection from "../components/title-section"
import SnippetsList from "../components/snippets-list"

class TagPageTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { tag } = this.props.pageContext
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const tagsGroup = data.tagsGroup.group

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <TitleSection title="Snippets" />
        <SnippetsList nodes={posts} tagsGroup={tagsGroup} activeTag={tag} />
      </Layout>
    )
  }
}

export default TagPageTemplate

export const pageQuery = graphql`
  query TagPageByName($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {tags: {in: [$tag]}}}) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
          }
        }
      }
    }
    tagsGroup: allMarkdownRemark(filter: {fields: {slug: {regex: "//snippets//"}}}) {
      group(field: frontmatter___tags) {
        name: fieldValue
        count: totalCount
      }
    }
  }
`
