import React from "react"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import { IoMdCloseCircle } from "react-icons/io"

import styles from "./tags-box.module.css"

export default function TagsBox(props) {
  // Get data
  const { tags } = props

  // Sort tags by count
  tags.sort((a, b) => (
    (a.count > b.count) ? -1 : 1
  ))

  return (
    <section id={styles.tags_box}>
      <div className={styles.title_wrapper}>
        <span className={styles.title}>Topics</span>
      </div>
      <div className={styles.content_wrapper}>
        {
          tags.map(tag => {
            let tagId
            if(tag.name === props.activeTag) {
              tagId = styles.active_tag
            }
            if(tag.count <= 1 && tagId == null) {
              return null
            }
            return (
              <div key={tag.name} id={tagId} className={styles.tag}>
                <Link to={`/snippets/topic/${kebabCase(tag.name)}/`}>
                  <div style={{ display: `flex` }}>
                    <div className={styles.arrow}>{'>'}</div>
                    <div>{tag.name} <span className={styles.tag_counter}>({tag.count})</span></div>
                  </div>
                </Link>
                <Link id={tagId} className={styles.close_icon} to="/snippets/">
                  <IoMdCloseCircle />
                </Link>
              </div>
            )
          })
        }
      </div>
    </section>
  )
}
