import React from "react"

import Article from "./article"
import SectionBreak from "../components/section-break"
import TagsBox from "./tags-box"
import styles from "./snippets-list.module.css"

class SnippetsList extends React.Component {
  render() {
    const { nodes, tagsGroup, activeTag } = this.props

    return (
      <section id={styles.snippets_list}>
        <div className={styles.tags_box}>
          <TagsBox tags={tagsGroup} activeTag={activeTag} />
        </div>
        <section className={styles.content}>
          {nodes.map(({node}, index) => {
            return (
              <div key={node.fields.slug}>
                <Article
                  title={node.frontmatter.title}
                  slug={node.fields.slug}
                  date={node.frontmatter.date}
                  update={node.frontmatter.update}
                  tags={node.frontmatter.tags}
                  html={node.html}
                />
                {(index === nodes.length - 1) ? null : <SectionBreak />}
              </div>
            )
          })}
        </section>
      </section>
    )
  }
}

export default SnippetsList
